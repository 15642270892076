import page404 from './404.json';
import contactPage from './contactPage.json';
import eventsPage from './eventsPage.json';
import footerPage from './footerPage.json';
import games from './games.json';
import homePage from './homePage.json';
import indoor from './indoor.json';
import industriesPage from './industriesPage.json';
import localAuthoritiesPage from './localAuthoritiesPage.json';
import mediaPage from './mediaPage.json';
import menuHeader from './menuHeader.json';
import navigationWayfindingPage from './navigation-wayfindingPage.json';
import partnersPage from './partnersPage.json';
import pressPage from './pressPage.json';
import privacyPolicy from './privacyPolicy.json';
import privacyPolicyRueil from './privacyPolicyRueil.json';
import privacyPolicyMissionHerault from './privacyPolicyMissionHerault.json';
import privacyPolicyColmarAventure from './privacyPolicyColmarAventure.json';
import privacyPolicyMontSaintMichel from './privacyPolicyMontSaintMichel.json';
import privacyPolicyParisRegionAventures from './privacyPolicyParisRegionAventures.json';
import productsPage from './productsPage.json';
import ressourcesPage from './ressourcesPage.json';
import Shop from './Shop.json';
import Sport from './Sport.json';
import TermOfUse from './TermOfUse.json';
import TermOfUsePro from './TermOfUsePro.json';
import culturePage from './culturePage.json';
import translation from './translation.json';
import travelPage from './travelPage.json';
import wemapArPage from './wemapArPage.json';
import WemapCookiesConsent from './WemapCookiesConsent.json';
import wemapDevelopersPage from './wemapDevelopersPage.json';
import wemapKioskPage from './wemapKioskPage.json';
import wemapPortalsPage from './wemapPortalsPage.json';
import wemapPrintPage from './wemapPrintPage.json';
import wemapProPage from './wemapProPage.json';
import wemapWebMobilePage from './wemapWebMobilePage.json';
import wemapTransportationPage from './wemapTransportationPage.json';

export default {
    page404,
    contactPage,
    eventsPage,
    footerPage,
    games,
    homePage,
    indoor,
    industriesPage,
    localAuthoritiesPage,
    mediaPage,
    menuHeader,
    navigationWayfindingPage,
    partnersPage,
    pressPage,
    privacyPolicy,
    privacyPolicyRueil,
    privacyPolicyMissionHerault,
    privacyPolicyParisRegionAventures,
    privacyPolicyColmarAventure,
    privacyPolicyMontSaintMichel,
    productsPage,
    ressourcesPage,
    Shop,
    Sport,
    TermOfUse,
    TermOfUsePro,
    culturePage,
    translation,
    travelPage,
    wemapArPage,
    WemapCookiesConsent,
    wemapDevelopersPage,
    wemapKioskPage,
    wemapPortalsPage,
    wemapPrintPage,
    wemapProPage,
    wemapWebMobilePage,
    wemapTransportationPage
};
