/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// eslint-disable-next-line no-unused-vars
import React from 'react';
import i18next from 'i18next';

import i18n from './src/locales/i18n';
import { I18nextProvider } from 'react-i18next';
import { PageContext } from './pageContext';

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => {
    return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({
    element, props
}) => {
    if (i18next.language !== props.pageContext.lang) {
        i18next.changeLanguage(props.pageContext.lang);
    }

    return <PageContext.Provider value={props.pageContext}>{element}</PageContext.Provider>;
};

const EVENT_START_CONVERSATION = 'DRIFT_START_CONVERSATION';

export const onRenderBody = ({ setHeadComponents }) => {

    // drift's minified snippet (version 4.1.0)
    const snippet = `
      !function() {
      var t = window.driftt = window.drift = window.driftt || [];
      if (!t.init) {
        if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
        t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
        t.factory = function(e) {
          return function() {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }, t.methods.forEach(function(e) {
          t[e] = t.factory(e);
        }), t.load = function(t) {
          var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
          o.type = "text/javascript", o.defer = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
          var i = document.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(o, i);
        };
      }
    }();
    drift.SNIPPET_VERSION = '0.3.1';
    drift.load('ahrxkxmha3cg');
    drift.on('ready', function (api) {
      drift.on('startConversation', function (event) {
        if (window.dataLayer) {
          window.dataLayer.push({'event': '${EVENT_START_CONVERSATION}'});
        }

        if (typeof window !== 'undefined' && window.gtag) {
              window.gtag('event', 'form send', {
                  event_category: 'form',
                  event_label: 'drift conversation'
              });
        }
      })
    });
  `;

    const delayedSnippet = `
      window.driftSnippetLoaded = false;
      window.driftSnippetLoading = false;
      window.driftSnippetLoader = function (callback) {
        if (!window.driftSnippetLoaded && !window.driftSnippetLoading) {
          window.driftSnippetLoading = true;
          function loader(tmp) {
            ${snippet}
            window.driftSnippetLoading = false;
            window.driftSnippetLoaded = true;
            if(callback) {callback()}
          };
          setTimeout(
            function () {
              "requestIdleCallback" in window
                ? requestIdleCallback(function () {loader()})
                : loader();
            },
            2000
          );
        }
      }
      window.addEventListener('scroll',function () {window.driftSnippetLoader()}, { once: true });
    `;

    setHeadComponents([
        <script
            key="plugin-drift"
            dangerouslySetInnerHTML={{ __html: delayedSnippet }}
        />
    ]);
};
